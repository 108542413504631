// Copyright (C) Microsoft Corporation. All rights reserved.

/**
 * Ad error names for internal logic
 */
export enum AdErrorNames {
    AdAlreadyPlaying = 'AdAlreadyPlaying',
    AdCouldNotBePlayed = 'AdCouldNotBePlayed',
    AdPlaybackDisabled = 'AdPlaybackDisabled',
    AdPlaybackTimeout = 'AdPlaybackTimeout',
    AdResponseTimeout = 'AdResponseTimeout',
}

/**
 * Ad event names for internal ad manager logic
 */
export enum AdEventNames {
    adComplete = 'adComplete',
    adEventOther = 'adEventOther',
    adFirstQuartile = 'adFirstQuartile',
    adFourthQuartile = 'adFourthQuartile',
    adImpressionEnd = 'adImpressionEnd',
    adImpressionStart = 'adImpressionStart',
    adPlaybackError = 'adPlaybackError',
    adPaused = 'adPaused',
    adRequestCompleted = 'adRequestCompleted',
    adResumed = 'adResumed',
    adSecondQuartile = 'adSecondQuartile',
    adStarted = 'adStarted',
    adThirdQuartile = 'adThirdQuartile',
}

/**
 * Supported ad placement types
 */
export enum AdPlacementType {
    interstitial = 'interstitial',
    preroll = 'preroll',
}

/**
 * Supported ad vendors
 */
export enum AdVendors {
    freewheel = 'freewheel',
    inmobi = 'inmobi',
    spotx = 'spotx',
}

/**
 * Supported consent keys
 */
export enum ConsentKeys {
    isConsentRequired = 'isConsentRequired',
    required = 'required',
    advertising = 'advertising',
    analytics = 'analytics',
    socialMedia = 'socialMedia',
}

/**
 * Event names sent from player to game via ZoneAdWrapper
 */
export enum EmittableGameEvents {
    adLevels = 'adLevels',
    adInterval = 'adInterval',
    adPlaybackCompleted = 'adPlaybackCompleted',
    muteGame = 'muteGame',
    pauseGame = 'pauseGame',
    resumeGame = 'resumeGame',
    unpauseGame = 'unpauseGame',
    quitGame = 'quitGame',
}

/**
 * Valid event types
 */
export enum EventTypes {
    adEvent,
    gameEvent,
}

/**
 * Supported game types
 */
export enum GameTypes {
    firstParty = 'firstParty',
    thirdParty = 'thirdParty',
}

/**
 * Supported hosting environments
 */
export enum HostingEnvironments {
    msn = 'msn',
    zone = 'zone',
    edgeCasualGamesHub = 'edgeCasualGamesHub',
}

/**
 * Supported locales
 */
export enum Locales {
    enCA = 'en-ca',
    enIN = 'en-in',
    enUS = 'en-us',
    esES = 'es-es',
    esUS = 'es-us',
    frCA = 'fr-ca',
}

/**
 * Externally-provided settings (set via query string)
 */
export enum PublicSettings {
    hostingEnvironment = 'hostingEnvironment',
    locale = 'locale',
    mcgId = 'mcgId',
    setLang = 'setLang',
    preroll = 'preroll',
    trfSrc = 'trfSrc',
    consent = 'consent',
}

/**
 * Event names received by player from game via ZoneAdWrapper
 */
export enum ReceivableGameEvents {
    completedLevel = 'completedLevel',
    gameEnded = 'gameEnded',
    gameMuted = 'gameMuted',
    gamePaused = 'gamePaused',
    gameQuit = 'gameQuit',
    gameResumed = 'gameResumed',
    gameScore = 'gameScore',
    gameUnmuted = 'gameUnmuted',
    gameUnpaused = 'gameUnPaused',
    newGame = 'newGame',
    readyForComm = 'readyForComm',
    startAdPlayback = 'startAdPlayback',
    readyForAdPlayback = 'readyForAdPlayback',
}

/**
 * Re-exporting App Insights severity levels so they can be used more generically
 */
export { SeverityLevel } from '../_utils';

/**
 * Standardized event names to send to our telemetry
 */
export enum TelemetryEventNames {
    GDK_GEQ = 'GDKGameEndedOrQuit',
    GDK_CL = 'GDKCompletedLevel',
    GDK_RC = 'GDKReadyToCommunicate',
    GDK_SAP = 'GDKStartAdPlayback',
    GDK_SR = 'GDKScoreReceived',
    HPL = 'HostingPageLoad',
    VA_AI = 'VideoAdvertisementAdInitiated',
    VA_APT = 'VideoAdvertisementAdPlayTimeout',
    VA_C = 'VideoAdvertisementComplete',
    VA_D = 'VideoAdvertisementDisabled',
    VA_E = 'VideoAdvertisementError',
    VA_IE = 'VideoAdvertisementImpressionEnd',
    VA_NRT = 'VideoAdvertisementNoResponseTimeout',
    VA_NVA = 'VideoAdvertisementNoVideoAvailable',
    VA_RI = 'VideoAdvertisementRequestInterstital',
    VA_RID = 'VideoAdvertisementRequestInterstitalDisabled',
    VA_RP = 'VideoAdvertisementRequestPreroll',
    VA_RPD = 'VideoAdvertisementRequestPrerollDisabled',
    VA_PT = 'VideoAdvertisementPlaybackTimeout',
    VAI_AB = 'VideoAdvertisementImpressionAutoplayBlocked',
    VAI_FiQC = 'VideoAdvertisementImpressionFirstQuartileComplete',
    VAI_FiQS = 'VideoAdvertisementImpressionFirstQuartileStart',
    VAI_FoQC = 'VideoAdvertisementImpressionFourthQuartileComplete',
    VAI_NA = 'VideoAdvertisementImpressionNoAd',
    VAI_O = 'VideoAdvertisementImpressionOther',
    VAI_PA = 'VideoAdvertisementImpressionPauseAd',
    VAI_SA = 'VideoAdvertisementImpressionSkipAd',
    VAI_SQC = 'VideoAdvertisementImpressionSecondQuartileComplete',
    VAI_TQC = 'VideoAdvertisementImpressionThirdQuartileComplete',
    LS_UE = 'LocalStorageUnavailableError',
}
