// Copyright (C) Microsoft Corporation. All rights reserved.

import { Observable, fromEvent } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { transformEvent as transformAdEvent } from './ad-manager';
import { transformEvent as transformGameEvent } from './game-manager';

import Message from '../_types/models';
import {
    EmittableGameEvents,
    EventTypes,
} from '../_types/enums';

import { logger } from '../_utils';

export interface IMessageEvent {
    name: string;
    data: string | { [key: string]: any } | null;
    type: EventTypes;
}

export interface IEventManager {
    send: (target: Window | HTMLIFrameElement, message: EmittableGameEvents, extra?: string) => void;
    windowEvents: Observable<IMessageEvent>;
}

export class EventManager implements IEventManager {
    windowEvents: Observable<IMessageEvent>;

    awaitingAck = [];

    constructor() {
        let eventStream;
        if (window.addEventListener) {
            eventStream = fromEvent(window, 'message');
        } else {
            eventStream = fromEvent(window, 'onmessage');
        }

        this.windowEvents = eventStream.pipe(
            // map((e: MessageEvent) => this.log(e)),
            map((e: MessageEvent) => this.reconcile(e)),
            map((e: MessageEvent) => this.transform(e)),
            filter((e: IMessageEvent) => this.isReceivableEvent(e)),
        );
    }

    send(target: Window | HTMLIFrameElement, message: EmittableGameEvents, extra?: string) {
        let msgExtra = '';
        let msgTarget;
        // prevent double send here
        if (target instanceof HTMLIFrameElement) {
            msgTarget = target.contentWindow;
        } else {
            msgTarget = target;
        }
        msgExtra = extra || '';
        msgTarget.postMessage(`${message}${msgExtra}`, '*');
        logger.cLog('sending event ', `${message}${msgExtra}`);
        if (message === EmittableGameEvents.pauseGame || message === EmittableGameEvents.resumeGame) {
            this.awaitingAck.push(new Message(message, true, msgTarget));
        }
    }

    log(e: MessageEvent): MessageEvent {
        logger.cLog('received event ', e.data);
        return e;
    }

    reconcile(e: MessageEvent): MessageEvent {
        this.awaitingAck.forEach((message: Message, i, arr) => {
            if (message.expectedResponse === e.data) {
                logger.cLog('Reconciled message', message.sent);
                arr.splice(i, 1);
            }
            return e;
        });
        return e;
    }

    transform(e: MessageEvent): IMessageEvent {
        const messageEvent: IMessageEvent = {
            name: 'noop',
            data: null,
            type: null,
        };

        // Game message event
        let { name, data, type } = transformGameEvent(e);

        if (name === null) {
            // Ad message event
            ({ name, data, type } = transformAdEvent(e));
        }

        if (typeof name === 'string') {
            messageEvent.name = name;
            messageEvent.data = data;
            messageEvent.type = type;
        }

        return messageEvent;
    }

    isReceivableEvent(e: IMessageEvent) {
        if (e.name !== 'noop') {
            logger.cLog('Received event ', e);
            return true;
        }

        return false;
    }
}
