// Copyright (C) Microsoft Corporation. All rights reserved.

import './styles.scss';
import wgpApp from './WGP/wgp-app';
import dependencyManager from './WGP/_services/dependency-manager';
import { PublicSettings } from './WGP/_types/enums';
import { logger, telemetry, Utilities } from './WGP/_utils';

window.addEventListener('unhandledrejection', (event) => {
    const message = 'Unhandled Promise Rejection';
    let error: Error;

    if ((typeof event === 'object') && event.reason && (event.reason instanceof Error)) {
        error = event.reason;
    } else {
        error = new Error(JSON.stringify(event));
    }

    window.onerror.apply(this, [message, window.location.href, null, null, error]);
});

// Get config and then bootstrap the player
function getGameConfig(): Promise<any> {
    // See if encoded gameInfo was passed to us.
    const gameInfoBase64 = Utilities.getUrlSearchParam('gameInfo', ['i']);
    if (gameInfoBase64 && gameInfoBase64.length) {
        const gameInfo = JSON.parse(Buffer.from(gameInfoBase64, 'base64').toString('ascii'));
        if (gameInfo.consent) gameInfo.consent = JSON.parse(gameInfo.consent);
        return Promise.resolve(gameInfo);
    }

    // See if gamePath was passed to us.
    const gamePath = Utilities.getUrlSearchParam('gamePath', ['i']);
    if (gamePath && gamePath.length) {
        if (__ENV__.gameConfig) { // See if we have gameInfo available locally.
            const gameInfo = __ENV__.gameConfig.find((config) => config.externalId === gamePath);

            if (gameInfo) {
                return Promise.resolve(gameInfo);
            }
        } else { // Get config from DB
            const uri = `${__ENV__.connections.wgpGames.uri}/${gamePath}?_ts=${Date.now()}`; // cache bust query param

            // Get config
            return fetch(uri, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': `${__ENV__.connections.wgpGames.key}`,
                },
                mode: 'cors',
            }).then((response) => {
                if (response.ok) {
                    return <object>response.json();
                }

                throw new Error(`Config fetch failed: ${response.status} -- ${response.statusText} | ${uri}`);
            })
                .catch((error) => {
                    throw error;
                });
        }
    }

    return Promise.reject(new Error(`Invalid game path: ${gamePath}`));
}

// Get config and then bootstrap the player
function bootstrapWGP() {
    const loadingIndicator = dependencyManager.loadingIndicators;
    loadingIndicator.showLoader([128, 128, 128, 0.65]);

    // Allow query params to override config settings for dev/QA
    const queryVals = {};
    new URLSearchParams(window.location.search).forEach((val, key) => {
        if (__ENV__.runtime.dev
            || Utilities.isDeveloperModeActive()
            || Object.values(PublicSettings).map((validKey) => validKey.toUpperCase() === key.toUpperCase())) {
            queryVals[key] = val;
        }
    });

    getGameConfig().then((gameConfigJson) => {
        if (!Utilities.hasUrlSearchParam('healthCheck')) { // don't load player for health check queries
            const playerSettings = {
                ...gameConfigJson,
                ...queryVals,
                externalId: gameConfigJson.externalId, // don't allow query override
            };

            if (typeof gameConfigJson.setLang === 'boolean') {
                playerSettings.setLang = gameConfigJson.setLang; // don't allow query override if `setLang` is specified in the config
            }

            // eslint-disable-next-line new-cap
            const wgp = new wgpApp(playerSettings);
            // Load UI & start player
            if (document.readyState === 'loading') { // Loading hasn't finished yet
                document.addEventListener('DOMContentLoaded', () => {
                    wgp.loadGamePlayer();
                });
            } else { // `DOMContentLoaded` has already fired
                wgp.loadGamePlayer();
            }
        }
    })
        .catch((error) => {
            const formattedError = Utilities.getFormattedError(error);
            telemetry.trackError({ name: 'getGameConfig error' }, { error: formattedError.message });
        })
        .finally(() => {
            loadingIndicator.removeLoader();
        });
}

try {
    bootstrapWGP();
} catch (exception) { // clean up any uncaught exceptions deeper in the code
    logger.cError(`Uncaught exception: ${exception}`);
    throw exception; // browser exception handling is usually better than the console
}
