// Copyright (C) Microsoft Corporation. All rights reserved.

import { logger } from './index';
import Utilities from './utilities';
import { SeverityLevel } from '../_types/enums';

interface ITrackName {
    name: string;
}

interface ITrackProperty {
    [key: string]: string|number;
}

interface ITrackMeasurement {
    [key: string]: number;
}

export interface ITelemetry {
    gameID?: string;
    gameType?: string;
    gameVersion?: string;
    hostingEnvironment?: string;
    locale?: string;
    trafficSrc?: string;
    flushEvent(): void;
    trackEvent(
        name: ITrackName,
        properties?: ITrackProperty,
        measurements?: ITrackMeasurement,
    ): void;
    trackError(
        name: ITrackName,
        properties?: ITrackProperty,
        measurements?: ITrackMeasurement,
    ): void;
    trackPageView(
        name?: ITrackName,
        url?: string,
        properties?: ITrackProperty,
        measurements?: ITrackMeasurement,
        duration?: number,
    ): void;
    trackException(
        exception: Error,
        handledAt?: string,
        properties?: ITrackProperty,
        measurements?: ITrackMeasurement,
        severityLevel?: SeverityLevel,
    ): void;
}

export class Telemetry implements ITelemetry {
    client: any;

    loggingPrefix: string;

    properties: any = {};

    set gameID(name: string) {
        this.properties.gameID = name;
    }

    set gameType(name: string) {
        this.properties.gameType = name;
    }

    set gameVersion(version: string) {
        this.properties.gameVersion = version;
    }

    set hostingEnvironment(environment: string) {
        this.properties.hostingEnvironment = environment;
    }

    set locale(locale: string) {
        this.properties.locale = locale;
    }

    set trafficSrc(src: string) {
        this.properties.trafficSrc = src;
    }

    constructor(client: any, loggingPrefix: string) {
        this.client = client;
        this.loggingPrefix = loggingPrefix;
        this.properties = {
            embedURL: window.location.href,
            slot: Utilities.getCookieValue('x-ms-routing-name') || 'production',
            version: __ENV__.runtime.version,
        };
        try { // don't break the app if same-origin policy prohibits accessing window.parent...
            this.properties.parentURL = window.parent.location.href;

            if (!this.properties.trafficSrc) {
                this.properties.trafficSrc = window.parent.document.referrer;
            }
        } catch (e) {
            // currently do nothing
        }
    }

    public trackEvent(name: ITrackName, properties?: ITrackProperty, measurements?: ITrackMeasurement): void {
        const props = { ...this.properties, ...properties };
        this.client.trackEvent(name, props, measurements);
        logger.cLog(`${this.loggingPrefix}: ${name.name}`, props, measurements);
    }

    // This is just a wrapper for logging to the error console
    public trackError(name: ITrackName, properties?: ITrackProperty, measurements?: ITrackMeasurement): void {
        const props = { ...this.properties, ...properties };
        this.client.trackEvent(name, props, measurements);
        logger.cError(`${this.loggingPrefix}: ${name.name}`, props, measurements);
    }

    public trackPageView(
        name?: ITrackName,
        url?: string,
        properties?: ITrackProperty,
        measurements?: ITrackMeasurement,
        duration?: number,
    ): void {
        const props = { ...this.properties, ...properties };
        this.client.trackPageView(name, url, props, measurements, duration);
        logger.cLog(`${this.loggingPrefix}: Page View`, name, url, props, measurements, duration);
    }

    public trackException(
        exception: Error,
        handledAt?: string,
        properties?: ITrackProperty,
        measurements?: ITrackMeasurement,
        severityLevel?: SeverityLevel,
    ): void {
        const props = { ...this.properties, ...properties };
        this.client.trackException(exception, handledAt, props, measurements, severityLevel);
        logger.cError(`${this.loggingPrefix}: Exception`, exception, handledAt, props, measurements, severityLevel);
    }

    public flushEvent(): void {
        this.client.flush();
    }
}
