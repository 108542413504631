// Copyright (C) Microsoft Corporation. All rights reserved.

import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

export { SeverityLevel } from '@microsoft/applicationinsights-web'; // Verbose, Information, Warning, Error, Critical

export default class AppInsightsClient {
    public appInsights: ApplicationInsights;

    public loggingPrefix: string;

    get appInsightsClient() {
        return this.appInsights;
    }

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: __ENV__.vendors.applicationInsights.appInsightsKey,
                namePrefix: __ENV__.vendors.applicationInsights.namePrefix,
                disableFetchTracking: false,
                disableAjaxTracking: true, // nothing but 3rd-party ad calls, save the storage space
            },
        });

        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem): boolean => {
            // Filter out exception noise (e.g. antivirus spam)
            if (envelope.baseType === 'ExceptionData') {
                const envelopeMessage: string = envelope.data.message ? envelope.data.message : null;
                const envelopeUrl: string = envelope.data.url ? envelope.data.url : null;

                // Antivirus spam: https://github.com/getsentry/sentry/issues/4958
                if (envelopeMessage.includes('JSON syntax error') && envelopeUrl.includes('main.js?attr')) {
                    return false;
                }
            }

            // Default: log event
            return true;
        });

        this.loggingPrefix = 'ApplicationInsightsEvent';
    }
}
