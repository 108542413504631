// Copyright (C) Microsoft Corporation. All rights reserved.

import { Subject, Subscription } from 'rxjs';

import dependencyManager from '../_services/dependency-manager';
import { IEventManager, IMessageEvent } from '../_services/event-manager';

import { IVideoPlayback } from '../_types/interfaces/ads';
import {
    AdEventNames,
    AdPlacementType,
    EventTypes,
    Locales,
    TelemetryEventNames,
} from '../_types/enums';

import { telemetry, Utilities, logger } from '../_utils';

declare global {
    interface Window {
        apntag: any;
    }
}

enum XandrEventTypes {
    // APN Callback Events
    // See https://docs.xandr.com/bundle/seller-tag/page/seller-tag/on-event.html for eventType descriptions
    EVENT_AD_AVAILABLE = 'adAvailable',
    EVENT_AD_BAD_REQUEST = 'adBadRequest',
    EVENT_AD_COLLAPSE = 'adCollapse',
    EVENT_AD_ERROR = 'adError',
    EVENT_AD_LOADED = 'adLoaded',
    EVENT_AD_LOADED_MEDIATED = 'adLoadedMediated',
    EVENT_AD_NO_BID = 'adNoBid',
    EVENT_AD_NO_BID_MEDIATED = 'adNoBidMediated',
    EVENT_AD_REQUESTED = 'adRequested',
    EVENT_AD_REQUEST_FAILURE = 'adRequestFailure',

    // Bannerstream callback events
    // See https://docs.xandr.com/bundle/seller-tag/page/seller-tag/publisher-page-level-options-for-outstream.html for event descriptions
    // @todo: do we need all of VPAID / VAST / AdUnit events?
    EVENT_AD_COMPLETE = 'adComplete',
    EVENT_COMPLETE = 'complete',
    EVENT_ENDED = 'ended',
    EVENT_IMPRESSION = 'impression',
    EVENT_FIRST_QUARTILE = 'firstQuartile',
    EVENT_LOADED = 'loaded',
    EVENT_MIDPOINT = 'midpoint',
    EVENT_START = 'start',
    EVENT_THIRD_QUARTILE = 'thirdQuartile',
    EVENT_VIDEO_PAUSE = 'pause',
    EVENT_VIDEO_RESUME = 'resume',
    EVENT_VIDEO_SKIP = 'skip',
    EVENT_VOLUME_CHANGE = 'volume-change',
}

export default class Xandr implements IVideoPlayback {
    private apntagRef: any;

    private apnPageOptions = {
        member: __ENV__.vendors.xandr.memberId,
        test: __ENV__.vendors.xandr?.testFlag,
        user: {
            externalUid: '',
            userIds: [{
                type: 'extendedIDs',
                eids: [{
                    id: '',
                    source: 'msft_muid',
                }],
            }],
        },
    };

    private astOptions = {
        targetId: __ENV__.vendors.xandr.videoContainerElementId,
        invCode: '',
        sizes: [640, 414],
        allowedFormats: ['video'],
        targetingParams: {},
        trafficSourceCode: '',
        rendererOptions: {
            cbNotification: this.setOutstreamCallbacks,
            playerTechnology: ['html5'],
            adText: 'Ad',
            showMute: true,
            showVolume: true,
            showProgressBar: true,
            autoInitialSize: true,
            allowFullscreen: true,
            playVideoVisibleThreshold: 0,
            sideStream: {
                enabled: false,
            },
            skippable: {
                videoThreshold: 15,
                videoOffset: 5,
                skipLocation: 'top-left',
                skipText: 'Video can be skipped in %%TIME%% seconds',
                skipButtonText: 'SKIP',
            },
        },
    };

    private astOptionsPlacement = __ENV__.vendors.xandr.placement;

    private astOptionsProviderId = __ENV__.vendors.xandr.providerId;

    private astOptionsRID = __ENV__.vendors.xandr.RID;

    private eventHubSubscription: Subscription;

    private placementLocale: string;

    private telemetryProperties = {
        adPlacementType: null,
        adSessionID: null, // Unique session identifier for play/ad session
        prevEvent: null,
        placementId: null,
        vendor: 'xandr',
        videoDuration: null,
        videoUrl: null,
    };

    private videoContainerElementId: string = __ENV__.vendors.xandr.videoContainerElementId;

    private videoContentContainerElementId: string = __ENV__.vendors.xandr.contentContainerId;

    private videoAdPauseDelta: number;

    private videoAdPauseTime: number;

    private videoAdResumeTime: number;

    private videoAdStartTime: number;

    private xandrASTScriptSrc = __ENV__.vendors.xandr.scriptSrc;

    private xandrASTScriptContainerId = __ENV__.vendors.xandr.scriptContainerId;

    private xandrEventLog: string[] = [];

    private get isApnTag(): boolean {
        return typeof this.apntagRef === 'object'
            && this.apntagRef?.loaded === true;
    }

    public eventHub: Subject<IMessageEvent> = new Subject();

    public set enableTestAd(testFlag: boolean) {
        this.apnPageOptions.test = testFlag ? 1 : 0;
    }

    public set locale(locale: string) {
        this.placementLocale = locale;
    }

    public set mcgId(mcgId: string) {
        // stub
    }

    public showPrerollClickToPlay = true;

    public vendorID = 'xandr';

    constructor(
        public eventManager: IEventManager = dependencyManager.eventManager,
    ) {
        this.eventManager.windowEvents.subscribe((event: IMessageEvent) => {
            // typescript is being silly here -- 'Object.values' returns an array, 'includes' checks its contents. There is no type mismatch.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (Object.values(XandrEventTypes).includes(event.name)) {
                this.handleAdEvent(event);
            }
        });
    }

    /** PUBLIC METHODS -- AD SETUP AND TEARDOWN */

    public playAd(preroll: boolean): Promise<boolean> {
        this.setAstOptionProperties(preroll);

        this.telemetryProperties.adPlacementType = preroll ? AdPlacementType.preroll : AdPlacementType.interstitial;
        this.telemetryProperties.placementId = this.astOptions.invCode;

        // Re-initialize our timers
        this.videoAdPauseDelta = 0;
        this.videoAdPauseTime = 0;
        this.videoAdResumeTime = 0;
        this.videoAdStartTime = 0;

        return this.initXandrAST()
            .then(() => {
                const adContainer = this.createAdContainer();
                if (!(adContainer instanceof HTMLElement)) {
                    throw new Error(`Couldn't create ad container #${this.videoContentContainerElementId}`);
                }

                return this.requestAds();
            })
            .then(() => true) // Success
            .catch((err: Error) => {
                const properties = {
                    ...this.telemetryProperties,
                    info: err.message,
                };
                telemetry.trackError({ name: TelemetryEventNames.VA_NVA }, properties);
                this.eventHub.next({ name: AdEventNames.adPlaybackError, data: {}, type: EventTypes.adEvent });
                return false; // Error playing the ad
            });
    }

    public cleanup(): void {
        telemetry.flushEvent();

        this.eventHubSubscription.unsubscribe();
        this.eventHubSubscription = null;

        Object.keys(this.telemetryProperties).filter((key) => key !== 'vendor').forEach((key) => {
            this.telemetryProperties[key] = null;
        });

        this.xandrEventLog = [];

        const nodes = [
            document.querySelector(`#${this.videoContainerElementId}`),
            document.querySelector(`#${this.videoContentContainerElementId}`),
        ];

        // Remove all non-zone image/iframe/script nodes that have been written into the page
        document.querySelectorAll('iframe, img, script').forEach((element: HTMLIFrameElement|HTMLImageElement|HTMLScriptElement) => {
            if (element.src) {
                const { host } = new URL(element.src);
                if (host !== window.location.host && !host.endsWith('zone.msn.com')) {
                    nodes.push(element);
                }
            } else {
                nodes.push(element);
            }
        });

        Utilities.removeNode(
            nodes.filter((node) => node != null),
        );
    }

    /** PRIVATE METHODS -- AD CONFIGURATION */

    private async initXandrAST(): Promise<void | Error> {
        if (this.isApnTag) {
            logger.cLog('this.apntagRef already loaded');
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            const xandrASTJs = document.createElement('script');
            xandrASTJs.src = this.xandrASTScriptSrc;
            xandrASTJs.id = this.xandrASTScriptContainerId;
            xandrASTJs.type = 'text/javascript';
            xandrASTJs.onload = resolve;
            xandrASTJs.onerror = reject;
            Utilities.appendNode(document.head, xandrASTJs);
        })
            .then(() => {
                // Make sure global apntag object is ready to go before continuing
                if (this.isApnTag) {
                    return Promise.resolve();
                }

                return new Promise<void>((resolve, reject) => {
                    let apnLoadTimeoutCount = 0;
                    const apnLoadTimeout = setInterval(
                        () => {
                            this.apntagRef = window.apntag;
                            if (this.isApnTag) {
                                clearInterval(apnLoadTimeout);
                                resolve();
                            } else {
                                apnLoadTimeoutCount += 1;
                            }

                            if (apnLoadTimeoutCount >= 10) {
                                clearInterval(apnLoadTimeout);
                                reject();
                            }
                        },
                        500,
                    );
                });
            })
            .catch((err) => Utilities.getFormattedError(err));
    }

    private createAdContainer(): HTMLElement {
        const adContainer = document.createElement('div');
        adContainer.setAttribute('id', this.videoContentContainerElementId); // adDiv
        adContainer.setAttribute('class', 'ad-player-container');

        const displayBase = document.createElement('div');
        displayBase.setAttribute('id', this.videoContainerElementId); // displayBase
        displayBase.setAttribute('style', 'width:100%; height:100%;');
        displayBase.setAttribute('data-setup', '{}');
        Utilities.appendNode(adContainer, displayBase);

        Utilities.appendNode(document.querySelector('#adContainer'), adContainer);
        return document.querySelector(`#${this.videoContentContainerElementId}`);
    }

    private requestAds(): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.apntagRef.anq.push(() => {
                    this.setApnPageOptions();

                    const newApnPageOptions = { ...this.apnPageOptions };
                    const newAstOptions = { ...this.astOptions };

                    this.apntagRef.setPageOpts(newApnPageOptions);
                    if (__ENV__.runtime.dev || Utilities.isDeveloperModeActive()) {
                        this.apntagRef.debug = true;
                    }

                    this.apntagRef.defineTag(newAstOptions);

                    this.setApnCallbacks();

                    this.apntagRef.anq.push(() => {
                        this.apntagRef.loadTags();
                    });

                    this.telemetryProperties.adSessionID = (Date.now() * 1000) + Math.round(Math.random() * 1000);
                });

                this.eventHubSubscription = this.eventHub.subscribe((event: IMessageEvent) => {
                    switch (event.name) {
                        case AdEventNames.adComplete:
                            resolve();
                            break;
                        case AdEventNames.adPlaybackError:
                            reject();
                            break;
                        default:
                            break;
                    }
                });
            } catch (err) {
                reject(new Error(err));
            }
        });
    }

    /** AST SCRIPT CONFIGURATION */

    /**
     * Callback function for Xandr ast options cbNotification property
     * For more info see https://docs.xandr.com/bundle/seller-tag/page/seller-tag/publisher-page-level-options-for-outstream.html
     * @param type VPAID, VAST, AdUnit
     * @param name Name of event
     * @param id AST target element ID
     * @param eventData Event-specific data
     */
    private setOutstreamCallbacks(type, name, id, eventData): void {
        window.postMessage({
            name,
            data: {
                type,
                id,
                eventData: eventData || {},
            },
        });
    }

    private setApnCallbacks(): void {
        Object.values(XandrEventTypes).forEach((eventType) => {
            let callbackFunction = null;

            switch (eventType) {
                case XandrEventTypes.EVENT_AD_AVAILABLE:
                    callbackFunction = (adObj) => {
                        window.postMessage({ name: eventType, data: { targetId: this.astOptions.targetId, adObj } });
                        this.apntagRef.anq.push(() => {
                            // signal to script that this DOM elemetn has been loaded and is ready to be populated with an ad
                            this.apntagRef.showTag(this.astOptions.targetId);
                        });
                    };
                    break;
                case XandrEventTypes.EVENT_AD_BAD_REQUEST:
                case XandrEventTypes.EVENT_AD_REQUEST_FAILURE:
                    callbackFunction = (adError) => {
                        window.postMessage({ name: eventType, data: { targetId: this.astOptions.targetId, adError } });
                    };
                    break;
                case XandrEventTypes.EVENT_AD_ERROR:
                    callbackFunction = (adError, adObj) => {
                        window.postMessage({ name: eventType, data: { targetId: this.astOptions.targetId, adError, adObj } });
                    };
                    break;
                case XandrEventTypes.EVENT_AD_LOADED:
                case XandrEventTypes.EVENT_AD_LOADED_MEDIATED:
                case XandrEventTypes.EVENT_AD_NO_BID:
                case XandrEventTypes.EVENT_AD_NO_BID_MEDIATED:
                    callbackFunction = (adObj) => {
                        window.postMessage({ name: eventType, data: { targetId: this.astOptions.targetId, adObj } });
                    };
                    break;
                case XandrEventTypes.EVENT_AD_COLLAPSE:
                case XandrEventTypes.EVENT_AD_REQUESTED:
                    callbackFunction = () => {
                        window.postMessage({ name: eventType, data: { targetId: this.astOptions.targetId } });
                    };
                    break;
                default:
                    break;
            }

            if (callbackFunction && typeof callbackFunction === 'function') {
                this.apntagRef.onEvent(
                    eventType,
                    this.astOptions.targetId,
                    callbackFunction,
                );
            }
        });
    }

    private setApnPageOptions(): void {
        if (this.apnPageOptions.test !== 1) {
            delete this.apnPageOptions.test;
        }

        const apnPageOptionsUserId = Utilities.getCookieValue('MUID');
        if (apnPageOptionsUserId.length > 0) {
            this.apnPageOptions.user.externalUid = apnPageOptionsUserId;
            this.apnPageOptions.user.userIds.forEach((userId) => {
                userId.eids.forEach((eid) => {
                    eid.id = apnPageOptionsUserId;
                });
            });
        } else {
            delete this.apnPageOptions.user;
        }
    }

    private setAstOptionProperties(preroll: boolean): void {
        let placementOptions = this.astOptionsPlacement.filter((placement) => placement.locale === this.placementLocale);

        if (placementOptions.length <= 0) {
            placementOptions = this.astOptionsPlacement.filter((placement) => placement.locale === Locales.enUS); // default
        }

        if (placementOptions.length > 1) {
            const adPlacementType = preroll ? AdPlacementType.preroll : AdPlacementType.interstitial;
            this.astOptions.invCode = placementOptions.find((placement) => placement.placementType === adPlacementType).placementId;
        } else {
            this.astOptions.invCode = placementOptions[0].placementId;
        }

        this.astOptions.trafficSourceCode = `pg:${this.astOptions.invCode};p:${this.astOptionsProviderId};r:${this.astOptionsRID}`;
    }

    /** EVENT HANDLING */

    private handleAdEvent(event: IMessageEvent): void {
        if (this.xandrEventLog.includes(event.name)) {
            // duplicate event, ignore
            logger.cWarn(`Already handled ${event.name}, skipping...`);
            return;
        }

        this.xandrEventLog.push(event.name);

        let eventType;
        let adError;
        if (event.data && typeof event.data === 'object') {
            ({ type: eventType, adError } = event.data);
        }

        // Only do this once, rather than every ad event call
        if (!this.telemetryProperties.videoDuration || !this.telemetryProperties.videoUrl) {
            const videoAd = Utilities.findActiveVideo(document);
            if (videoAd !== null) {
                this.telemetryProperties.videoDuration = videoAd.duration;
                this.telemetryProperties.videoUrl = videoAd.src;
            }
        }

        const properties = {
            ...this.telemetryProperties,
            eventType,
            split: 0,
            astEvent: event.name,
        };

        switch (event.name) {
            case XandrEventTypes.EVENT_AD_BAD_REQUEST:
            case XandrEventTypes.EVENT_AD_ERROR:
            case XandrEventTypes.EVENT_AD_NO_BID:
            case XandrEventTypes.EVENT_AD_NO_BID_MEDIATED:
            case XandrEventTypes.EVENT_AD_REQUEST_FAILURE: {
                const errorProperties = {
                    ...properties,
                    errorCode: adError?.code,
                    errorInfo: adError?.exception,
                    errorMessage: adError?.errMessage,
                };
                telemetry.trackError({ name: TelemetryEventNames.VA_E }, errorProperties);
                this.eventHub.next({ name: AdEventNames.adPlaybackError, data: {}, type: EventTypes.adEvent });

                break;
            }
            case XandrEventTypes.EVENT_AD_AVAILABLE:
            case XandrEventTypes.EVENT_AD_LOADED:
            case XandrEventTypes.EVENT_AD_LOADED_MEDIATED:
                telemetry.trackEvent({ name: TelemetryEventNames.VA_AI }, properties);
                // this.eventHub.next({ name: AdEventNames.adImpressionStart }); // @todo: clean up if not needed
                break;

            case XandrEventTypes.EVENT_START:
                this.videoAdStartTime = Date.now();
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_FiQS }, properties);
                this.eventHub.next({
                    name: AdEventNames.adImpressionStart,
                    data: { duration: properties.videoDuration },
                    type: EventTypes.adEvent,
                });
                break;

            case XandrEventTypes.EVENT_FIRST_QUARTILE:
                properties.split = this.calculateSplit();
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_FiQC }, properties);
                this.eventHub.next({ name: AdEventNames.adFirstQuartile, data: { duration: properties.split }, type: EventTypes.adEvent });
                break;

            case XandrEventTypes.EVENT_MIDPOINT:
                properties.split = this.calculateSplit();
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_SQC }, properties);
                this.eventHub.next({ name: AdEventNames.adSecondQuartile, data: { duration: properties.split }, type: EventTypes.adEvent });
                break;

            case XandrEventTypes.EVENT_THIRD_QUARTILE:
                properties.split = this.calculateSplit();
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_TQC }, properties);
                this.eventHub.next({ name: AdEventNames.adThirdQuartile, data: { duration: properties.split }, type: EventTypes.adEvent });
                break;

            case XandrEventTypes.EVENT_COMPLETE:
                properties.split = this.calculateSplit();
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_FoQC }, properties);
                this.eventHub.next({ name: AdEventNames.adFourthQuartile, data: { duration: properties.split }, type: EventTypes.adEvent });
                this.eventHub.next({ name: AdEventNames.adComplete, data: {}, type: EventTypes.adEvent });
                break;

            case XandrEventTypes.EVENT_AD_COMPLETE:
                // this.eventHub.next({ name: AdEventNames.adComplete, data: {}, type: EventTypes.adEvent }); // @todo: clean up if not needed
                telemetry.trackEvent({ name: TelemetryEventNames.VA_C }, properties);
                break;

            case XandrEventTypes.EVENT_ENDED:
                telemetry.trackEvent({ name: TelemetryEventNames.VA_C }, properties);
                break;

            case XandrEventTypes.EVENT_VIDEO_PAUSE:
                this.videoAdPauseTime = Date.now();
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_PA }, properties);
                this.eventHub.next({ name: AdEventNames.adPaused, data: {}, type: EventTypes.adEvent });
                break;

            case XandrEventTypes.EVENT_VIDEO_RESUME:
                this.videoAdResumeTime = Date.now();
                this.eventHub.next({ name: AdEventNames.adResumed, data: {}, type: EventTypes.adEvent });
                break;

                // @todo: look into logging chrome event for blocked/heavy ads
                // case XandrEventTypes.EVENT_AD_AUTO_PLAY_BLOCKED:

            case XandrEventTypes.EVENT_VIDEO_SKIP:
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_SA }, properties);
                this.eventHub.next({ name: AdEventNames.adComplete, data: {}, type: EventTypes.adEvent });
                break;

            default:
                telemetry.trackEvent({ name: TelemetryEventNames.VAI_O }, properties);
                break;
        }

        this.telemetryProperties.prevEvent = event.name;
    }

    /** UTILITIES */

    private calculateSplit(): number {
        this.videoAdPauseDelta += (this.videoAdResumeTime - this.videoAdPauseTime);
        this.videoAdPauseTime = 0;
        this.videoAdResumeTime = 0;
        return (Date.now() - (this.videoAdStartTime + this.videoAdPauseDelta)) / 1000;
    }
}

export const transformEvent = (event: MessageEvent): IMessageEvent => {
    const messageEvent: IMessageEvent = {
        name: null,
        data: null,
        type: EventTypes.adEvent,
    };

    if (typeof event.data === 'object') {
        const { name, data } = event.data;
        if (Object.values(XandrEventTypes).includes(name)) {
            messageEvent.name = name;
            messageEvent.data = data;
        }
    }

    return messageEvent;
};
