// Copyright (C) Microsoft Corporation. All rights reserved.

import { Utilities } from '../_utils';

export interface ILoadingIndicators {
    removeLoader: () => void;
    showLoader: (bgColor?: [number, number, number, number]) => void;
}

export class LoadingIndicators implements ILoadingIndicators {
    loadingIndicatorId = 'loading-indicator';

    activeLoader = false;

    constructor() { }

    public removeLoader() {
        const loadingIndicatorEl: HTMLElement = document.querySelector(`#${this.loadingIndicatorId}`);
        if (loadingIndicatorEl) {
            loadingIndicatorEl.style.display = 'none';
            Utilities.removeNode([loadingIndicatorEl]);
        }
        this.activeLoader = false;
    }

    public showLoader(bgColor?: [number, number, number, number]) {
        if (this.activeLoader) return;
        const loader = document.createElement('div');
        loader.setAttribute('id', this.loadingIndicatorId);

        let style = 'position:absolute;top:0;left:0;height:100%;width:100%;z-index:99999999;';
        const bgStyle = [0, 0, 0, 1].map((colorVal, index) => { // default black/opaque
            if (bgColor) {
                if (index < (bgColor.length - 1)) { // rgb color value
                    if (typeof bgColor[index] === 'number' && bgColor[index] >= 0 && bgColor[index] <= 255) {
                        return bgColor[index];
                    }
                } else if (bgColor[index] >= 0 && bgColor[index] <= 1) {
                    return bgColor[index];
                }
            }
            return colorVal; // default
        });
        style += `background:rgba(${bgStyle.join()});`;
        loader.setAttribute('style', style);

        loader.innerHTML = `
        <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>`;

        const styles = document.createElement('style');
        styles.innerHTML = `
            .lds-grid {
                z-index: 10100;
                position:absolute;
                left: calc(50%);
                top: calc(50% - 32px);
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 64px;
                height: 64px;
            }
            .lds-grid div {
                position: absolute;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background: #fff;
                animation: lds-grid 1.2s linear infinite;
            }
            .lds-grid div:nth-child(1) {
                top: 6px;
                left: 6px;
                animation-delay: 0s;
            }
            .lds-grid div:nth-child(2) {
                top: 6px;
                left: 26px;
                animation-delay: -0.4s;
            }
            .lds-grid div:nth-child(3) {
                top: 6px;
                left: 45px;
                animation-delay: -0.8s;
            }
            .lds-grid div:nth-child(4) {
                top: 26px;
                left: 6px;
                animation-delay: -0.4s;
            }
            .lds-grid div:nth-child(5) {
                top: 26px;
                left: 26px;
                animation-delay: -0.8s;
            }
            .lds-grid div:nth-child(6) {
                top: 26px;
                left: 45px;
                animation-delay: -1.2s;
            }
            .lds-grid div:nth-child(7) {
                top: 45px;
                left: 6px;
                animation-delay: -0.8s;
            }
            .lds-grid div:nth-child(8) {
                top: 45px;
                left: 26px;
                animation-delay: -1.2s;
            }
            .lds-grid div:nth-child(9) {
                top: 45px;
                left: 45px;
                animation-delay: -1.6s;
            }
            @keyframes lds-grid {
                0%, 100% {
                opacity: 1;
                }
                50% {
                opacity: 0.5;
                }
            }
        `;

        const loaderElement = Utilities.prependNode(document.querySelector('#adContainer'), loader);
        Utilities.prependNode(loaderElement, styles);

        this.activeLoader = true;
    }
}
