// Copyright (C) Microsoft Corporation. All rights reserved.

import appInsights from '../_vendor/app-insights'; // re-exporting so we have all our app-insights-specific logic in one spot
import { Telemetry } from './telemetry';
import Logger from './logger';
import Overlay from './overlay';

export { SeverityLevel } from '../_vendor/app-insights';

// eslint-disable-next-line new-cap
const telemetryClient = new appInsights();
export const telemetry = new Telemetry(
    telemetryClient.appInsightsClient,
    telemetryClient.loggingPrefix,
);
export const logger = new Logger();
export const overlay = new Overlay();
export { default as Utilities } from './utilities';
