// Copyright (C) Microsoft Corporation. All rights reserved.

import { filter } from 'rxjs/operators';
import dependencyManager from './dependency-manager';
import { IEventManager, IMessageEvent } from './event-manager';
import { ReceivableGameEvents, EmittableGameEvents, EventTypes } from '../_types/enums';

export interface IGameManager {
    muteGame: (target: HTMLElement) => Promise<IMessageEvent>;
    pauseGame: (target: HTMLElement) => Promise<IMessageEvent>;
    resumeGame: (target: HTMLElement) => Promise<IMessageEvent>;
}

export class GameManager implements IGameManager {
    constructor(
        private eventManager: IEventManager = dependencyManager.eventManager,
    ) { }

    public pauseGame(target: HTMLIFrameElement): Promise<IMessageEvent> {
        return this.requestAction(target, EmittableGameEvents.pauseGame, ReceivableGameEvents.gamePaused);
    }

    public resumeGame(target: HTMLIFrameElement): Promise<IMessageEvent> {
        return this.requestAction(target, EmittableGameEvents.resumeGame, ReceivableGameEvents.gameResumed);
    }

    public muteGame(target: HTMLIFrameElement): Promise<IMessageEvent> {
        return this.requestAction(target, EmittableGameEvents.muteGame, ReceivableGameEvents.gameMuted);
    }

    private requestAction(
        target: HTMLIFrameElement,
        action: EmittableGameEvents,
        expectedResponse: ReceivableGameEvents,
    ): Promise<IMessageEvent> {
        return new Promise((resolve, reject) => { // @todo: consider using .promisify? http://bluebirdjs.com/docs/anti-patterns.html
            this.eventManager.send(target, action);
            this.eventManager.windowEvents.pipe(
                filter((e: IMessageEvent) => e.name === expectedResponse),
            )
                .subscribe((e) => resolve(e));

            setTimeout(
                () => reject(new Error(`Timed out waiting for ${action}`)),
                __ENV__.settings.games.pauseFailedTimeout,
            );
        });
    }
}

export const transformEvent = (event: MessageEvent): IMessageEvent => {
    const messageEvent = {
        name: null,
        data: null,
        type: EventTypes.gameEvent,
    };

    if (typeof event.data === 'string') {
        const gameScore = event.data.match(/<score>([0-9]+)<\/score>/);
        if (gameScore && gameScore.length === 2) {
            messageEvent.name = ReceivableGameEvents.gameScore;
            [, messageEvent.data] = gameScore;
        } else if (ReceivableGameEvents[event.data]) {
            messageEvent.name = event.data;
        }
    }

    return messageEvent;
};
