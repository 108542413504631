// Copyright (C) Microsoft Corporation. All rights reserved.

/**
 * Model definitions
 */
export default class Message {
    constructor(value, needsReconciliation, target, expectedResponse?: string) {
        this.sent = value;
        this.needsReconciliation = needsReconciliation;
        this.timeSent = Date.now();
        this.target = target;
        this.expectedResponse = expectedResponse;
    }

    id?:string;

    sent?: string;

    received?: any;

    needsReconciliation?: boolean;

    timeSent?: number;

    timeReceived?: number;

    complete?: boolean;

    target?: any;

    expectedResponse?: string;
}
