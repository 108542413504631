// Copyright (C) Microsoft Corporation. All rights reserved.

import { AdManager, IAdManager } from './ad-manager';
import { EventManager, IEventManager } from './event-manager';
import { GameManager, IGameManager } from './game-manager';
import { LoadingIndicators, ILoadingIndicators } from './loading-indicators';
import { IVideoPlayback } from '../_types/interfaces/ads';
import PlaceholderVideo from '../_vendor/placeholderVideo';
import Xandr from '../_vendor/xandr';

export interface IDependencyManager {
    adManager: IAdManager;
    eventManager: IEventManager;
    gameManager: IGameManager;
    loadingIndicators: ILoadingIndicators;
    placeholderVideo: IVideoPlayback;
    xandr: IVideoPlayback;
}

class DependencyManager {
    _adManager: IAdManager | null = null;

    _eventManager: IEventManager | null = null;

    _gameManager: IGameManager | null = null;

    _loadingIndicators: LoadingIndicators | null = null;

    _placeholderVideo: IVideoPlayback | null = null;

    _xandr: IVideoPlayback | null = null;

    constructor() { }

    get adManager(): IAdManager {
        if (!this._adManager) {
            this._adManager = new AdManager();
        }
        return this._adManager;
    }

    get eventManager(): IEventManager {
        if (!this._eventManager) {
            this._eventManager = new EventManager();
        }
        return this._eventManager;
    }

    get gameManager(): IGameManager {
        if (!this._gameManager) {
            this._gameManager = new GameManager();
        }
        return this._gameManager;
    }

    get loadingIndicators(): LoadingIndicators {
        if (!this._loadingIndicators) {
            this._loadingIndicators = new LoadingIndicators();
        }
        return this._loadingIndicators;
    }

    get placeholderVideo(): IVideoPlayback {
        if (!this._placeholderVideo) {
            this._placeholderVideo = new PlaceholderVideo();
        }
        return this._placeholderVideo;
    }

    get xandr(): IVideoPlayback {
        if (!this._xandr) {
            this._xandr = new Xandr();
        }
        return this._xandr;
    }
}

export default new DependencyManager();
