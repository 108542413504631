// Copyright (C) Microsoft Corporation. All rights reserved.

import { Utilities } from './index';

export default class Logger {
    debugMode: boolean;

    constructor() {
        this.debugMode = Utilities.isDeveloperModeActive();
    }

    public cLog(...logItems: any[]): void {
        this.logToConsole('info', logItems);
    }

    public cError(...logItems: any[]): void {
        this.logToConsole('error', logItems);
    }

    public cWarn(...logItems: any[]): void {
        this.logToConsole('warn', logItems);
    }

    private logToConsole(level: string, msg: any[]): void {
        if (__ENV__.runtime.dev || this.debugMode) {
            const output = msg.reduce((prev, cur) => {
                if (cur == null) { // using == instead of === here to capture both null and undefined values
                    return prev;
                }

                return `${prev} ${JSON.stringify(cur)}`;
            });
            console[level](`@@WGP: ${output}`); // eslint-disable-line no-console
        }
    }
}
