// Copyright (C) Microsoft Corporation. All rights reserved.

import { detect } from 'detect-browser';
import { fromEvent, Subscription } from 'rxjs';
import { logger } from '.';

declare global {
    interface Window {
        enableDeveloperMode: (debugKey: string) => void;
        disableDeveloperMode: () => void;
    }
}

export default class Utilities {
    private static developerModeTimeoutHours = 1;

    public static findActiveVideo(doc: Document): HTMLVideoElement | null {
        let videoAd: HTMLVideoElement = doc.querySelector("video[src]:not([src=''])");
        if (videoAd === null) {
            const frames = doc.querySelectorAll('iframe');
            for (let i = 0; !videoAd && i < frames.length; i += 1) {
                try {
                    videoAd = Utilities.findActiveVideo(frames[i].contentDocument);
                } catch (err) {
                    // must be a frame from another domain
                }
            }
        }
        return videoAd || null;
    }

    // Using this instread of URLSearchParams because it's case-insensitive
    public static getUrlSearchParam(name: string, flags?: [string], url?: string): string | null {
        const regex = `[\\?&]${name}=([^&#]*)`;
        const search = url || window.location.search;

        let rxflags = '';
        if (flags && flags.length > 0) {
            rxflags = flags.reduce((prev, cur) => prev.concat(cur));
        }

        const results = new RegExp(regex, rxflags).exec(search);

        return results ? results[1] : null;
    }

    // Case-sensitive
    public static hasUrlSearchParam(name: string): boolean {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.has(name);
    }

    public static initalizeDeveloperMode(): void {
        try { // don't break the app if same-origin policy prohibits accessing window.parent...
            window.enableDeveloperMode = (debugKey: string) => {
                if (debugKey === __ENV__.runtime.debugKey) {
                    localStorage.setItem('developerModeLastActivated', new Date().getTime().toString());
                    logger.cLog(`Developer mode activated for ${this.developerModeTimeoutHours} hour(s)`);
                } else {
                    logger.cLog('Debug Key Incorrect');
                }
            };
            window.disableDeveloperMode = () => {
                localStorage.removeItem('developerModeLastActivated');
                logger.cLog('Developer mode deactivated');
            };
        } catch (error) {
            logger.cLog(error);
        }
    }

    public static isDeveloperModeActive(): boolean {
        const hoursSinceDeveloperModeActivated = this.getHoursSinceDeveloperModeActivated();
        if (hoursSinceDeveloperModeActivated < this.developerModeTimeoutHours) {
            return true;
        }
        localStorage.removeItem('developerModeLastActivated');
        return false;
    }

    private static getHoursSinceDeveloperModeActivated(): number {
        const lastUpdated = new Date(parseInt(localStorage.getItem('developerModeLastActivated'), 10));
        if (lastUpdated instanceof Date && !Number.isNaN(lastUpdated.getTime())) {
            const now = new Date();

            const differenceInTime = now.getTime() - lastUpdated.getTime(); // ms
            const differenceInHours = differenceInTime / (1000 * 60 * 60); // ms=>secs * secs=>mins * mins=>hrs
            const differenceInHoursRounded = Math.round(differenceInHours * 100) / 100; // Two decimal places.

            return differenceInHoursRounded;
        }
        return this.developerModeTimeoutHours; // If developermode has not been activated, return the max timeout.
    }

    public static transition(
        element: HTMLElement,
        style: string,
        endState: string,
        animationTimeout: number,
        attribute?: string,
    ): Promise<void> {
        let animationTimeoutRef: NodeJS.Timeout;
        let endEventSubscription: Subscription;
        return new Promise<void>((resolve) => {
            const attr = attribute || 'display';
            const endEvent = fromEvent(element, 'animationend');
            element.classList.add(style);
            endEventSubscription = endEvent.subscribe(() => {
                element.style[attr] = endState;
                element.classList.remove(style);
                resolve();
            });
            animationTimeoutRef = setTimeout(
                () => {
                    element.style[attr] = endState;
                    element.classList.remove(style);
                    resolve();
                },
                animationTimeout, // taking too long, resolve & continue
            );
        }).finally(() => {
            clearTimeout(animationTimeoutRef);
            animationTimeoutRef = null;
            endEventSubscription.unsubscribe();
            endEventSubscription = null;
        });
    }

    public static validateMcgId(mcgId: string): boolean {
        const hexadecimal32RegularExpression = /^[a-fA-F0-9]{32}$/g;
        const mcgIdArray = mcgId.split('_');
        const mcgIdUniqueVal = (mcgIdArray.length === 2) ? mcgIdArray[1] : mcgIdArray[0];

        return hexadecimal32RegularExpression.test(mcgIdUniqueVal);
    }

    // -- Browser types not covered yet, that detect() may find:
    // 'aol','yandexbrowser','kakaotalk','samsung','silk','miui','beaker','phantomjs','crios' ,'pie',
    // 'netfront','bb10','android','ios','facebook','instagram','ios-webview','curl','searchbot';

    public static isBrowserChrome = (name = ''): boolean => ['chrome', 'chromium-webview'].indexOf(name || detect().name) >= 0;

    public static isBrowserFirefox = (name = ''): boolean => ['firefox', 'fxios'].indexOf(name || detect().name) >= 0;

    public static isBrowserSafari = (name = ''): boolean => ['safari'].indexOf(name || detect().name) >= 0;

    public static isBrowserOpera = (name = ''): boolean => ['opera', 'opera-mini'].indexOf(name || detect().name) >= 0;

    public static isBrowserEdge = (name = ''): boolean => ['edge', 'edge-ios', 'edge-chromium'].indexOf(name || detect().name) >= 0;

    public static isBrowserInternetExplorer = (name = ''): boolean => ['ie'].indexOf(name || detect().name) >= 0;

    public static getBrowserName = (): string => {
        const browserName = detect().name;
        if (Utilities.isBrowserChrome(browserName)) { return 'Chrome'; }
        if (Utilities.isBrowserFirefox(browserName)) { return 'Firefox'; }
        if (Utilities.isBrowserSafari(browserName)) { return 'Safari'; }
        if (Utilities.isBrowserOpera(browserName)) { return 'Opera'; }
        if (Utilities.isBrowserEdge(browserName)) { return 'Edge'; }
        if (Utilities.isBrowserInternetExplorer(browserName)) { return 'Internet Explorer'; }
        return 'Unknown';
    };

    public static getBrowserVersion = (): string => detect().version;

    public static getBrowserPrimaryVersion = (): string => {
        const versionString = detect().version;
        return versionString.split('.')[0];
    };

    public static removeNode(nodes: Element[]): void {
        nodes.forEach((node) => {
            if (Object.prototype.hasOwnProperty.call(node, 'remove')) {
                node.remove();
            } else {
                node.parentNode.removeChild(node);
            }
        });
    }

    public static appendNode(parent: Element, child: Element): Element {
        if (Object.prototype.hasOwnProperty.call(parent, 'append')) {
            parent.append(child);
            return child;
        }

        return parent.appendChild(child);
    }

    public static prependNode(parent: Element, child: Element): Element {
        if (Object.prototype.hasOwnProperty.call(parent, 'prepend')) {
            parent.prepend(child);
            return child;
        }

        const firstChild = parent.firstElementChild;
        return parent.insertBefore(child, firstChild);
    }

    public static clientHasLocalStorage(): boolean {
        try {
            localStorage.setItem('test', 'test');
            if (localStorage.getItem('test') !== 'test') {
                localStorage.removeItem('test');
                return false;
            }
            localStorage.removeItem('test');
            return true;
        } catch (exception) {
            return false;
        }
    }

    public static getFormattedError(err: any): Error {
        if (err instanceof Error) {
            return err;
        }

        if (typeof err === 'string') {
            return new Error(err);
        }

        return new Error(JSON.stringify(err));
    }

    public static getCookieValue(cookieName: string): string {
        const name = `${cookieName}=`;
        const cookie = document.cookie
            .split(';')
            .find((row) => row.trim().startsWith(name));

        return typeof cookie === 'string' ? cookie.trim().substring(name.length) : '';
    }
}
