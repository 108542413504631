// Copyright (C) Microsoft Corporation. All rights reserved.

import './wgp-app.scss';

import * as Handlebars from 'handlebars/dist/handlebars';

import dependencyManager from './_services/dependency-manager';
import { IAdManager } from './_services/ad-manager';
import { IEventManager, IMessageEvent } from './_services/event-manager';
import { ILoadingIndicators } from './_services/loading-indicators';

import adLoadingTpl from './_templates/ad-loading.tpl';
import clickToPlayTpl from './_templates/click-to-play.tpl';
import gameOverTpl from './_templates/game-over.tpl';
import unsupportedBrowserTpl from './_templates/unsupported-browser.tpl';
import unsupportedGameTpl from './_templates/unsupported-game.tpl';

import {
    AdEventNames,
    ConsentKeys,
    EmittableGameEvents,
    GameTypes,
    HostingEnvironments,
    Locales,
    ReceivableGameEvents,
    TelemetryEventNames,
} from './_types/enums';
import { IAdPlaybackResult } from './_types/interfaces/ads';
import { IPlayerSettings, IAdsConfiguration } from './_types/interfaces/player';

import {
    logger,
    telemetry,
    overlay,
    Utilities,
} from './_utils';

export interface IWebGamePlayer {
    loadGamePlayer(): void;
}

export default class WebGamePlayer implements IWebGamePlayer {
    private adLoadingTemplate: string = adLoadingTpl;

    private animationTimeout = 150;

    private clickToPlayTemplate: string = clickToPlayTpl;

    private prerollAdvertisementTimeoutActive = false;

    private duplicateAdRequestBufferActive = false;

    private duplicateAdRequestBufferLength = 2000;

    private gameFrame: HTMLIFrameElement = document.querySelector('#gameFrame');

    private gameOverTemplate: string = gameOverTpl;

    private settings: IPlayerSettings = { // default values
        enableTestAd: null,
        interstitialAdInterval: null,
        prerollAdInterval: null,
        interstitialAdSkipCount: 0,
        externalId: '',
        gameABTest: null, // @todo: consolidate game configuration settings
        gameDisplayName: '',
        gameType: GameTypes.firstParty,
        gameVersion: 'NA',
        hasScore: false,
        hostingEnvironment: HostingEnvironments.zone,
        language: Locales.enUS,
        levels: __ENV__.settings.games.levels,
        locale: Locales.enUS,
        maxWidth: __ENV__.settings.games.maxWidth,
        mcgId: '',
        minHeight: __ENV__.settings.games.minHeight,
        path: null,
        playInterstitial: null,
        playPreroll: null,
        setLang: false,
        support: {
            chrome: true,
            firefox: true,
            safari: true,
            opera: true,
            edge: true,
            internetExplorer: false,
        },
        consent: {
            isConsentRequired: false,
            required: true,
            advertising: true,
            analytics: true,
            socialMedia: true,
        },
    };

    private translations: any = __LANG__[Locales.enUS]; // default

    private unsupportedBrowserTemplate = unsupportedBrowserTpl;

    private unsupportedGameTemplate = unsupportedGameTpl;

    constructor(
        playerSettings: {
            externalId: string;
            title: string;
            path: string;
            trfSrc?: string;
            consent?: string;
            [key: string]: string | number | boolean;
        },
        private adManager: IAdManager = dependencyManager.adManager,
        private eventManager: IEventManager = dependencyManager.eventManager,
        private loadingIndicators: ILoadingIndicators = dependencyManager.loadingIndicators,
    ) {
        Utilities.initalizeDeveloperMode();

        // Merge config settings
        this.mergeSettings(playerSettings);

        const adsConfiguration: IAdsConfiguration = __ENV__.settings.ads.find(
            (config) => config.hostingEnvironment === this.settings.hostingEnvironment,
        );

        // Player settings
        this.setAdPlayerDependencies(adsConfiguration);
        this.initializeAdSkipCountLocalStorage(adsConfiguration);

        if (Object.keys(__LANG__).includes(this.settings.locale)) {
            this.translations = __LANG__[this.settings.locale];
        }
        if (Object.keys(__ENV__.settings.games.languageMap).includes(this.settings.locale)) {
            this.settings.language = __ENV__.settings.games.languageMap[this.settings.locale];
        } else {
            this.settings.language = this.settings.locale;
        }

        // Telemetry
        telemetry.gameID = playerSettings.externalId;
        telemetry.gameType = this.settings.gameType;
        telemetry.gameVersion = this.settings.gameVersion;
        telemetry.hostingEnvironment = this.settings.hostingEnvironment;
        telemetry.locale = this.settings.locale;
        if (playerSettings.trfSrc && (typeof playerSettings.trfSrc === 'string')) {
            telemetry.trafficSrc = decodeURIComponent(playerSettings.trfSrc);
        }
        telemetry.trackPageView();
        telemetry.trackEvent({ name: TelemetryEventNames.HPL });

        // App logic
        eventManager.windowEvents.subscribe((event: IMessageEvent) => {
            switch (event.name) {
                // Ready For Comm
                case ReceivableGameEvents.readyForComm:
                    telemetry.trackEvent({ name: TelemetryEventNames.GDK_RC });

                    // this has to happen for some games to emit level complete events
                    eventManager.send(
                        this.gameFrame,
                        EmittableGameEvents.adLevels,
                        `: ${this.settings.levels}`, // we have to pass this separately from the event, as it's dynamic
                    );
                    eventManager.send(
                        this.gameFrame,
                        EmittableGameEvents.adInterval,
                        `: ${this.settings.interstitialAdInterval}`, // we have to pass this separately from the event, as it's dynamic
                    );
                    break;
                    // Level Complete
                case ReceivableGameEvents.completedLevel:
                    telemetry.trackEvent({ name: TelemetryEventNames.GDK_CL }); // We need to track this for 3rd-party game plays
                    break;
                    // Start Ad Playback
                case ReceivableGameEvents.startAdPlayback:
                    telemetry.trackEvent({ name: TelemetryEventNames.GDK_SAP });
                    this.playInterstitialAd();
                    break;
                    // Game Ended / Quit
                case ReceivableGameEvents.gameEnded:
                case ReceivableGameEvents.gameQuit:
                    telemetry.trackEvent({ name: TelemetryEventNames.GDK_GEQ });
                    // tslint:disable-next-line:no-boolean-literal-compare
                    if (this.settings.hasScore !== true) {
                        this.reloadGame();
                    }
                    break;
                    // Game Score
                case ReceivableGameEvents.gameScore:
                    telemetry.trackEvent({ name: TelemetryEventNames.GDK_SR });
                    this.reloadGame(event.data);
                    // tslint:disable-next-line:no-boolean-literal-compare
                    if (this.settings.hasScore !== true) {
                        logger.cError(
                            // eslint-disable-next-line max-len
                            `Unexpected value for this.settings.hasScore: ${this.settings.hasScore}. This may cause issues with game over/game restart`,
                        );
                    }
                    break;
                default: break;
            }
        });
    }

    private initializeAdSkipCountLocalStorage(hostingEnvironment: IAdsConfiguration): void {
        if (hostingEnvironment.interstitialAdSkipCount) {
            if (Utilities.clientHasLocalStorage()) {
                const interstitialAdSkipCountString = localStorage.getItem('interstitialAdSkipCount');
                const interstitialAdSkipCountOriginalString = localStorage.getItem('interstitialAdSkipCountOriginal');

                if (interstitialAdSkipCountString && interstitialAdSkipCountOriginalString) {
                    const interstitialAdSkipCount = parseInt(interstitialAdSkipCountString, 10);
                    const interstitialAdSkipCountOriginal = parseInt(interstitialAdSkipCountOriginalString, 10);

                    if (
                        hostingEnvironment.interstitialAdSkipCount === interstitialAdSkipCountOriginal
                        && interstitialAdSkipCount <= hostingEnvironment.interstitialAdSkipCount // Catch user modified localstorage
                    ) {
                        // If the original ad skip ammount hasn't changed we continue using the local storage value;
                        this.settings.interstitialAdSkipCount = interstitialAdSkipCount;
                    } else {
                        // If the original ad skip ammount has changed we reset the localstorage value as new settings have arrived.
                        localStorage.setItem('interstitialAdSkipCount', hostingEnvironment.interstitialAdSkipCount.toString());
                        localStorage.setItem('interstitialAdSkipCountOriginal', hostingEnvironment.interstitialAdSkipCount.toString());

                        this.settings.interstitialAdSkipCount = hostingEnvironment.interstitialAdSkipCount;
                    }
                } else {
                    localStorage.setItem('interstitialAdSkipCount', hostingEnvironment.interstitialAdSkipCount.toString());
                    localStorage.setItem('interstitialAdSkipCountOriginal', hostingEnvironment.interstitialAdSkipCount.toString());

                    this.settings.interstitialAdSkipCount = hostingEnvironment.interstitialAdSkipCount;
                }
            } else {
                telemetry.trackError({ name: TelemetryEventNames.LS_UE });
                this.settings.interstitialAdSkipCount = hostingEnvironment.interstitialAdSkipCount;
            }
        }
    }

    /**
     * Initial game player load
     */
    public loadGamePlayer(): void {
        if (!this.gameFrame) {
            throw new Error('Game HTML not found!');
        }

        const browserName = Utilities.getBrowserName();
        const browserPrimaryVersionNumber = parseInt(Utilities.getBrowserPrimaryVersion(), 10);
        if (Utilities.isBrowserInternetExplorer() && (browserPrimaryVersionNumber < 11)) {
            this.showUnsupportedBrowser(browserName, browserPrimaryVersionNumber.toString());
        } else if (
            (Utilities.isBrowserChrome() && !this.settings.support.chrome)
            || (Utilities.isBrowserFirefox() && !this.settings.support.firefox)
            || (Utilities.isBrowserSafari() && !this.settings.support.safari)
            || (Utilities.isBrowserEdge() && !this.settings.support.edge)
            || (Utilities.isBrowserInternetExplorer() && !this.settings.support.internetExplorer)
        ) {
            this.showUnsupportedGame(this.settings.gameDisplayName, browserName);
        } else if (this.settings.playPreroll && !this.settings.consent.isConsentRequired) {
            if (this.adManager.showPrerollClickToPlay) {
                this.showClickToPlay()
                    .then(() => this.playPrerollAd())
                    .finally(() => {
                        this.loadGame();
                    });
            } else {
                this.playPrerollAd()
                    .finally(() => {
                        this.loadGame();
                    });
            }
        } else {
            if (this.settings.consent.isConsentRequired) {
                logger.cLog('Consent is required, skipping preroll advertisement.');
            } else {
                // log skip of scheduled ad play
                telemetry.trackEvent({ name: TelemetryEventNames.VA_RPD });
            }

            if (this.adManager.showPrerollClickToPlay) {
                this.showClickToPlay()
                    .then(() => {
                        this.loadGame();
                    });
            } else {
                this.loadGame();
            }
        }
    }

    /**
     * Reload current game
     * Used for games that don't have their own notion of levels or ongoing play
     */
    private reloadGame(score?: string | object): void {
        const gameScore = typeof score === 'string' ? score : '0';
        this.hideGame();
        this.showGameOver(gameScore)
            .then(() => this.playReloadGamePrerollAd())
            .finally(() => this.loadGame(true));
    }

    playReloadGamePrerollAd(): Promise<IAdPlaybackResult | Error> {
        if (this.settings.playInterstitial && !this.prerollAdvertisementTimeoutActive && !this.settings.consent.isConsentRequired) {
            if (this.settings.interstitialAdSkipCount > 0 && !this.duplicateAdRequestBufferActive) {
                this.setPrerollAdvertisementTimeout();
                this.settings.interstitialAdSkipCount -= 1;
                localStorage.setItem('interstitialAdSkipCount', this.settings.interstitialAdSkipCount.toString());
                logger.cLog(`Skipping Reload Preroll Ad. Skips Remaining: ${this.settings.interstitialAdSkipCount}`);

                this.eventManager.send(this.gameFrame, EmittableGameEvents.adPlaybackCompleted); // Let game know interstitial ad is done
                return Promise.resolve(<IAdPlaybackResult>{ success: true });
            }

            this.loadingIndicators.showLoader();
            this.showAdPlayingText(true);
            return this.playPrerollAd();
        }

        if (this.settings.playInterstitial) {
            const logMsg = this.settings.consent.isConsentRequired
                ? 'Consent is required, skipping preroll advertisement.'
                : 'Preroll advertisement timeout is still active';
            logger.cLog(logMsg);
        } else {
            // log skip of scheduled ad play
            telemetry.trackEvent({ name: TelemetryEventNames.VA_RPD });
        }
        this.eventManager.send(this.gameFrame, EmittableGameEvents.adPlaybackCompleted); // Let game know interstitial ad is done
        return Promise.resolve(<IAdPlaybackResult>{ success: true });
    }

    setPrerollAdvertisementTimeout() {
        if (!this.prerollAdvertisementTimeoutActive && this.settings.prerollAdInterval > 0) {
            this.prerollAdvertisementTimeoutActive = true;
            setTimeout(() => { this.prerollAdvertisementTimeoutActive = false; }, this.settings.prerollAdInterval);
        }
    }

    /**
     * Game frame load
     */
    private loadGame(reload?: boolean): void {
        if (!reload) { // initial load, so set styles
            if (this.settings.maxWidth > 0) {
                this.gameFrame.style.maxWidth = `${this.settings.maxWidth}px`;
            }
            if (this.settings.minHeight > 0) {
                this.gameFrame.style.minHeight = `${this.settings.minHeight}px`;
            }
        }

        // @todo: find a more efficient way to do this
        let gamePath = this.settings.path;
        if (this.settings.setLang && this.settings.language) {
            const queryStart = gamePath.includes('?') ? '&' : '?';
            gamePath += `${queryStart}locale=${this.settings.language}`;
        }
        if (this.settings.gameABTest) {
            const queryStart = gamePath.includes('?') ? '&' : '?';
            gamePath += `${queryStart}abtest=${this.settings.gameABTest}`;
        }

        (this.gameFrame).src = gamePath;

        this.showGame();
        this.loadingIndicators.removeLoader();
    }

    private playPrerollAd(): Promise<IAdPlaybackResult | Error> {
        this.showAdPlayingText(true);
        this.loadingIndicators.showLoader();

        const adStartSubscription = this.adManager.adEvents([
            AdEventNames.adImpressionStart,
            AdEventNames.adPlaybackError,
        ]).subscribe(() => {
            // Remove loading indicator once ad is ready to play
            this.loadingIndicators.removeLoader();
        });

        return this.adManager.playPrerollAd()
            .catch(() => <IAdPlaybackResult> {
                success: false,
            })
            .finally(() => {
                this.setPrerollAdvertisementTimeout();
                this.removeAdPlayingText();
                adStartSubscription.unsubscribe();
            });
    }

    private playInterstitialAd(): Promise<IAdPlaybackResult> {
        if (!this.settings.playInterstitial || this.settings.consent.isConsentRequired) {
            if (this.settings.consent.isConsentRequired) {
                logger.cLog('Consent is required, skipping interstitial advertisement.');
            } else {
                // log skip of scheduled ad play
                telemetry.trackEvent({ name: TelemetryEventNames.VA_RID });
            }
            this.eventManager.send(this.gameFrame, EmittableGameEvents.adPlaybackCompleted);
            return Promise.resolve(<IAdPlaybackResult>{ success: true });
        }
        if (this.duplicateAdRequestBufferActive) { // This catches duplicate calls that drains adSkipsCount.
            logger.cLog(`Multiple Interstitial Ads Requested Within ${this.duplicateAdRequestBufferLength / 1000} Second(s) Limit`);
            return Promise.resolve(<IAdPlaybackResult>{ success: true });
        }
        if (this.settings.interstitialAdSkipCount > 0) {
            this.settings.interstitialAdSkipCount -= 1;
            localStorage.setItem('interstitialAdSkipCount', this.settings.interstitialAdSkipCount.toString());
            logger.cLog(`Skipping Interstitial Ad. Skips Remaining: ${this.settings.interstitialAdSkipCount}`);

            this.duplicateAdRequestBufferActive = true;
            setTimeout(() => { this.duplicateAdRequestBufferActive = false; }, this.duplicateAdRequestBufferLength);

            this.eventManager.send(this.gameFrame, EmittableGameEvents.adPlaybackCompleted); // Let game know interstitial ad is done
            return Promise.resolve(<IAdPlaybackResult>{ success: true });
        }

        this.loadingIndicators.showLoader();
        this.showAdPlayingText(false);
        this.hideGame();

        const adStartSubscription = this.adManager.adEvents([
            AdEventNames.adImpressionStart,
            AdEventNames.adPlaybackError,
        ]).subscribe(() => {
            // Remove loading indicator once ad is ready to play
            this.loadingIndicators.removeLoader();
        });

        return this.adManager.playInterstitialAd()
            .then((res: IAdPlaybackResult) => res)
            .catch(() => <IAdPlaybackResult> {
                success: false,
            })
            .finally(() => {
                if (!this.adManager.isAdPlaying) {
                    this.eventManager.send(this.gameFrame, EmittableGameEvents.adPlaybackCompleted); // Let game know interstitial ad is done
                    this.removeAdPlayingText();
                    this.showGame();
                    this.loadingIndicators.removeLoader();
                }
                adStartSubscription.unsubscribe();
            });
    }

    private showClickToPlay(): Promise<any> {
        const template = Handlebars.compile(this.clickToPlayTemplate);
        const htmlOut = template({
            c2p_title: this.settings.gameDisplayName,
            c2p_click: (!this.settings.gameDisplayName) ? this.translations.c2p_click : '',
            c2p_btn: this.translations.c2p_btn,
        });
        return overlay.showOverlay(
            htmlOut,
            '#gameContainer',
            '#btnPlayAgain',
            'clickToPlay',
        )
            .catch((err: Error) => {
                logger.cError(err.message);
            });
    }

    private showGameOver(score?: string): Promise<any> {
        const template = Handlebars.compile(this.gameOverTemplate);
        const htmlOut = template({
            score,
            game_over_score: this.translations.game_over_score,
            game_over_enjoyed: this.translations.game_over_enjoyed,
            game_over_btn: this.translations.game_over_btn,

        });
        return overlay.showOverlay(
            htmlOut,
            '#gameContainer',
            '#btnPlayAgain',
            'gameOver',
        )
            .catch((err: Error) => {
                logger.cError(err.message);
            });
    }

    private showUnsupportedBrowser(browserName: string, browserVersion: string): Promise<any> {
        const template = Handlebars.compile(this.unsupportedBrowserTemplate);
        const htmlOut = template({
            unsupported_browser_title: this.translations.unsupported_browser_title,
            unsupported_browser_1: this.translations.unsupported_browser_1,
            browserName,
            unsupported_browser_2: this.translations.unsupported_browser_2,
            browserVersion,
            unsupported_browser_3: this.translations.unsupported_browser_3,
            unsupported_browser_4: this.translations.unsupported_browser_4,
        });
        return overlay.showOverlay(
            htmlOut,
            '#gameContainer',
            null,
            'unsupportedBrowser',
        )
            .catch((err: Error) => {
                logger.cError(err.message);
            });
    }

    private showUnsupportedGame(gameName: string, browserName: string): Promise<any> {
        const template = Handlebars.compile(this.unsupportedGameTemplate);
        const htmlOut = template({
            unsupported_game_title: this.translations.unsupported_game_title,
            unsupported_game_1: this.translations.unsupported_game_1,
            gameName,
            unsupported_game_2: this.translations.unsupported_game_2,
            browserName,
            unsupported_game_3: this.translations.unsupported_game_3,
            unsupported_game_4: this.translations.unsupported_game_4,
        });
        return overlay.showOverlay(
            htmlOut,
            '#gameContainer',
            null,
            'unsupportedGame',
        )
            .catch((err: Error) => {
                logger.cError(err.message);
            });
    }

    private showAdPlayingText(isPreroll: boolean): Promise<any> {
        const template = Handlebars.compile(this.adLoadingTemplate);
        const htmlOut = template({
            isPreroll,
            ad_player_begin: this.translations.ad_player_begin,
            ad_player_continue: this.translations.ad_player_continue,
        });
        return overlay.showOverlay(
            htmlOut,
            '#fullContainer',
            null,
            'adPlayingText',
            'afterbegin',
        )
            .catch((err: Error) => {
                logger.cError(err.message);
            });
    }

    private removeAdPlayingText(): void {
        const adPlayingNode: HTMLElement = document.querySelector('#adPlayingText');
        if (adPlayingNode) {
            overlay.hideOverlay(adPlayingNode, 'adPlayingText');
        }
    }

    private showGame(): void {
        Utilities.transition(this.gameFrame, 'fadein', 'visible', this.animationTimeout, 'visibility');
    }

    private hideGame(): void {
        Utilities.transition(this.gameFrame, 'fadeout', 'hidden', this.animationTimeout, 'visibility');
    }

    private mergeSettings(playerSettings: object): void {
        const normalizedSettings: any = {}; // TODO: Add type interface
        Object.keys(playerSettings).forEach((prop) => {
            const propUpperCase = prop.toUpperCase();
            switch (propUpperCase) {
                case 'ADINTERVAL':
                    normalizedSettings.INTERSTITIALADINTERVAL = playerSettings[prop];
                    break;
                case 'INTERSTITIAL':
                    normalizedSettings.PLAYINTERSTITIAL = playerSettings[prop];
                    break;
                case 'PREROLL':
                    normalizedSettings.PLAYPREROLL = playerSettings[prop];
                    break;
                case 'TITLE':
                    normalizedSettings.GAMEDISPLAYNAME = playerSettings[prop];
                    break;
                case 'TYPE':
                    normalizedSettings.GAMETYPE = playerSettings[prop];
                    break;
                case 'VERSION':
                    normalizedSettings.GAMEVERSION = playerSettings[prop];
                    break;
                default:
                    normalizedSettings[propUpperCase] = playerSettings[prop];
                    break;
            }
        });

        Object.keys(this.settings).forEach((prop) => {
            const propUpperCase = prop.toUpperCase();
            if (Object.prototype.hasOwnProperty.call(normalizedSettings, propUpperCase)) {
                this.settings[prop] = this.validateSettings(prop, normalizedSettings[propUpperCase]);
            }
        });
    }

    private validateSettings(name: string, value: any): any {
        let newValue = null;
        switch (name) {
            case 'interstitialAdInterval':
                if (parseInt(value, 10) > 0) {
                    newValue = value;
                }
                break;
            case 'externalId':
            case 'gameABTest':
            case 'gameDisplayName':
                newValue = value;
                break;
            case 'gameVersion':
                if (/^[\w.]+$/.test(value)) {
                    newValue = value;
                }
                break;
            case 'gameType':
                if (Object.values(GameTypes).includes(value)) {
                    newValue = value;
                }
                break;
            case 'enableTestAd':
            case 'hasScore':
            case 'playInterstitial':
            case 'playPreroll':
            case 'setLang':
                if (typeof value === 'boolean') {
                    newValue = value;
                }
                if (typeof value === 'string') {
                    if (value.toUpperCase() === 'TRUE') {
                        newValue = true;
                    }
                    if (value.toUpperCase() === 'FALSE') {
                        newValue = false;
                    }
                }
                break;
            case 'hostingEnvironment':
                if (Object.values(HostingEnvironments).includes(value)) {
                    newValue = value;
                }
                break;
            case 'locale':
                if (Object.values(Locales).includes(value)) {
                    newValue = value;
                }
                break;
            case 'mcgId':
                if (typeof value === 'string' && Utilities.validateMcgId(value)) {
                    newValue = value;
                }
                break;
            case 'path':
                if (typeof value === 'string') {
                    const gamePath = decodeURIComponent(value);
                    if (this.isGamePathAllowed(gamePath)
                        || (__ENV__.runtime.dev && !__ENV__.runtime.uat) // allow any path for local build
                        || Utilities.isDeveloperModeActive()) {
                        newValue = gamePath;
                    }
                }
                break;
            case 'consent':
                newValue = {};
                Object.keys(ConsentKeys).forEach((consentKey) => {
                    if (typeof value[consentKey] === 'boolean') { newValue[consentKey] = value[consentKey]; }
                });
                break;
            default: break;
        }

        return (newValue !== null && typeof newValue !== 'undefined') ? newValue : this.settings[name];
    }

    private isGamePathAllowed(gamePath: string): boolean {
        try {
            const parsedUrl = new URL(gamePath);
            return __ENV__.settings.games.validHostDomains
                .some((hostName) => {
                    const rgxp = new RegExp(hostName, 'i');
                    return rgxp.test(parsedUrl.hostname);
                });
        } catch (e) {
            // ignore invalid gamePath
        }
        return false;
    }

    private setAdPlayerDependencies(adsConfiguration: IAdsConfiguration): void {
        if (this.settings.playInterstitial === null) {
            this.settings.playInterstitial = adsConfiguration.playInterstitial;
        }
        if (this.settings.playPreroll === null) {
            this.settings.playPreroll = adsConfiguration.playPreroll;
        }
        if (this.settings.prerollAdInterval === null) {
            this.settings.prerollAdInterval = adsConfiguration.prerollAdInterval;
        }
        if (this.settings.interstitialAdInterval === null) {
            this.settings.interstitialAdInterval = adsConfiguration.interstitialAdInterval;
        }

        this.adManager.configurePlayers({
            ...adsConfiguration,
            enableTestAd: this.settings.enableTestAd,
            locale: this.settings.locale,
        });
    }
}
