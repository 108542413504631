// Copyright (C) Microsoft Corporation. All rights reserved.

import { fromEvent } from 'rxjs';

import { Utilities } from './index';

export default class Overlay {
    private activeOverlays: string[] = [];

    private animationTimeout = 150;

    public showOverlay(
        template: string,
        parentSelector: string,
        clickSelector: string|null,
        overlayId: string,
        position: InsertPosition = 'beforeend',
    ): Promise<any> { // @todo: type this for real
        let overlayError: string;
        let overlayNode: HTMLElement;
        let clickElement: HTMLElement;

        // Create and validate overlay elements
        if (this.activeOverlays.includes(overlayId)) {
            overlayError = `Overlay '${overlayId}' already exists`;
        }

        if (!overlayError) {
            const container: HTMLElement = document.querySelector(parentSelector);
            if (!container) {
                overlayError = `Invalid parent selector '${parentSelector}'`;
            } else {
                container.insertAdjacentHTML(position, template);
                switch (position) {
                    case 'afterbegin':
                        overlayNode = <HTMLElement>container.firstElementChild;
                        break;
                    case 'afterend':
                        overlayNode = <HTMLElement>container.nextElementSibling;
                        break;
                    case 'beforebegin':
                        overlayNode = <HTMLElement>container.previousElementSibling;
                        break;
                    case 'beforeend':
                    default:
                        overlayNode = <HTMLElement>container.lastElementChild;
                        break;
                }
            }
        }

        if (!overlayError && clickSelector !== null) {
            clickElement = document.querySelector(clickSelector);
            if (!clickElement) {
                overlayError = `Invalid click selector '${clickSelector}'`;
            }
        }

        if (overlayError) {
            if (overlayNode) {
                Utilities.removeNode([overlayNode]);
            }
            return Promise.reject(
                new Error(overlayError),
            );
        }

        // Add overlayId to array, so we don't re-add an existing overlay
        this.activeOverlays.push(overlayId);

        return new Promise<void>((resolve) => {
            // Attach click event if desired
            if (clickElement) {
                const clickEvent = fromEvent(clickElement, 'click');
                const clickEventSubscription = clickEvent.subscribe(() => {
                    Utilities.transition(overlayNode, 'fadeout', 'none', this.animationTimeout)
                        .finally(() => {
                            clickEventSubscription.unsubscribe();
                            this.hideOverlay(overlayNode, overlayId);
                            resolve();
                        });
                });
            } else {
                resolve();
            }

            // Show the overlay if it's hidden
            if (overlayNode.style.display === 'none') {
                Utilities.transition(overlayNode, 'fadein', 'block', this.animationTimeout);
            }
        });
    }

    public hideOverlay(overlayNode: HTMLElement, overlayId: string): void {
        if (overlayNode && overlayNode instanceof Element) {
            Utilities.removeNode([overlayNode]);
        }

        const overlayIndex = this.activeOverlays.indexOf(overlayId);
        if (overlayIndex > -1) {
            this.activeOverlays.splice(overlayIndex, 1);
        }
    }
}
