// Copyright (C) Microsoft Corporation. All rights reserved.

import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import dependencyManager from './dependency-manager';
import { IMessageEvent } from './event-manager';

import { AdEventNames, AdErrorNames, TelemetryEventNames } from '../_types/enums';
import { IAdPlaybackResult, IVideoPlayback } from '../_types/interfaces/ads';
import { IAdsConfiguration } from '../_types/interfaces/player';

import { logger, telemetry } from '../_utils';
import { transformEvent as transformXandrEvent } from '../_vendor/xandr';

export interface IAdManager {
    adEvents: (adEventNames: AdEventNames[]) => Observable<any>;
    configurePlayers: (adsConfiguration: IAdsConfiguration) => void;
    isAdPlaying: boolean;
    playInterstitialAd: () => Promise<IAdPlaybackResult | Error>;
    playPrerollAd: () => Promise<IAdPlaybackResult | Error>;
    showPrerollClickToPlay: boolean;
}

export class AdManager implements IAdManager {
    private _placeholderVideo: IVideoPlayback;

    private _xandr: IVideoPlayback;

    // @xxx
    // private adPlayTimeoutRef: {
    //     [key: string]: NodeJS.Timeout,
    // } = {};
    private adPlayTimeoutRef: NodeJS.Timeout;

    private adPlayTimeoutSubscription: Subscription;

    private adResponseTimeout = 3; // seconds

    private adStartTimeoutRef: NodeJS.Timeout;

    private adStartTimeoutSubscription: Subscription;

    private adPartner: IVideoPlayback;

    private isPlaying = false;

    get isAdPlaying(): boolean {
        return this.isPlaying;
    }

    get showPrerollClickToPlay(): boolean {
        return this.adPartner.showPrerollClickToPlay;
    }

    constructor(
        public placeholderVideo = dependencyManager.placeholderVideo,
        // public xandr = dependencyManager.xandr,
    ) {
        this._placeholderVideo = placeholderVideo;
        // this._xandr = xandr;
    }

    public configurePlayers(adsConfiguration: IAdsConfiguration) {
        switch (adsConfiguration.adVendor) {
            case 'xandr':
                this.adPartner = this._xandr;
                this.adPartner.locale = adsConfiguration?.locale;
                if (typeof adsConfiguration?.enableTestAd === 'boolean') {
                    this.adPartner.enableTestAd = adsConfiguration.enableTestAd;
                }
                break;
            default:
                this.adPartner = this._placeholderVideo;
        }
    }

    public playPrerollAd(): Promise<IAdPlaybackResult | Error> {
        return this.playAd(this.adPartner, true);
    }

    public playInterstitialAd(): Promise<IAdPlaybackResult | Error> {
        return this.playAd(this.adPartner, false);
    }

    public adEvents([...events]: AdEventNames[]): Observable<any> {
        return this.adPartner.eventHub.asObservable().pipe(
            map((event) => ({ name: event.name, data: event.data })),
            // typescript is being silly here -- 'events' is an array, 'includes' checks its contents. There is no type mismatch.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            filter((event) => events.includes(event.name)),
        );
    }

    private playAd(partner: IVideoPlayback, preroll: boolean): Promise<IAdPlaybackResult | Error> {
        if (!this.isPlaying) {
            this.isPlaying = true;

            const adEventName = preroll ? TelemetryEventNames.VA_RP : TelemetryEventNames.VA_RI;
            telemetry.trackEvent({ name: adEventName });

            // Attempt to play ad
            // Reject & continue if we hit adStartTimeout or adPlayTimeout
            return Promise.race([this.adStartTimeout(), this.adPlayTimeout(), partner.playAd(preroll)])
                .then(() => <IAdPlaybackResult>{ success: true })
                .catch(() => {
                    throw new Error(AdErrorNames.AdCouldNotBePlayed);
                })
                .finally(() => {
                    partner.cleanup();
                    this.cleanup();
                });
        }

        // An ad is already playing, so don't play another one
        logger.cError(AdErrorNames.AdAlreadyPlaying);
        return Promise.reject(new Error(AdErrorNames.AdAlreadyPlaying));
    }

    /**
     * Set ad start timeout -- if ad SDK has not loaded an ad w/in timeout window, proceed to gameplay
     * @returns Promise<Error>
     */
    private adStartTimeout(): Promise<Error> {
        return new Promise((resolve, reject) => {
            this.adStartTimeoutRef = setTimeout(
                () => {
                    telemetry.trackError({ name: TelemetryEventNames.VA_NRT }); // @todo: clean up / centralize error telemetry
                    clearTimeout(this.adStartTimeoutRef);
                    reject(new Error(AdErrorNames.AdResponseTimeout));
                },
                this.adResponseTimeout * 1000,
            );

            // If we receive the adImpressionStart or adPlaybackError events, SDK has responded and we can clear the ad start timeout.
            this.adStartTimeoutSubscription = this.adEvents([
                AdEventNames.adImpressionStart, AdEventNames.adPlaybackError,
            ]).subscribe(() => {
                if (this.adStartTimeoutRef) {
                    clearTimeout(this.adStartTimeoutRef);
                    this.adStartTimeoutRef = null;
                }
            });
        });
    }

    private adPlayTimeout(): Promise<Error> {
        return new Promise((resolve, reject) => {
            this.adPlayTimeoutRef = setTimeout(
                () => {
                    telemetry.trackError({ name: TelemetryEventNames.VA_APT }); // @todo: clean up / centralize error telemetry
                    clearTimeout(this.adPlayTimeoutRef);
                    reject(new Error(AdErrorNames.AdPlaybackTimeout));
                },
                20 * 1000,
            );

            // If we receive the adComplete or adPlaybackError events, SDK has responded and we can clear the ad playback timeout.
            this.adPlayTimeoutSubscription = this.adEvents([
                AdEventNames.adComplete, AdEventNames.adPlaybackError,
            ]).subscribe(() => {
                if (this.adPlayTimeoutRef) {
                    clearTimeout(this.adPlayTimeoutRef);
                    this.adPlayTimeoutRef = null;
                }
            });
        });
        /* //@xxx
        return new Promise((resolve, reject) => {
            const splitTimeouts = {
                firstQSplit: 0.25,
                secondQSplit: 0.5,
                thirdQSplit: 0.75,
                fourthQSplit: 1,
            };

            this.adPlayTimeoutSubscription = this.adEvents([
                AdEventNames.adImpressionStart,
                AdEventNames.adFirstQuartile,
                AdEventNames.adSecondQuartile,
                AdEventNames.adThirdQuartile,
                AdEventNames.adFourthQuartile,
                AdEventNames.adPaused,
                AdEventNames.adResumed,
            ]).subscribe((event) => {
                let split = null;

                switch (event.name) {
                    case AdEventNames.adImpressionStart: {
                        const duration = event?.context?.duration;
                        if (typeof duration === 'number' && duration > 0) {
                            Object.keys(splitTimeouts).forEach((splitKey) => {
                                if (!this.adPlayTimeoutRef[splitKey]) {
                                    const timeout = Math.ceil(duration * splitTimeouts[splitKey]) + 4; // seconds

                                    const myRef = setTimeout(
                                        () => {
                                            telemetry.trackError({ name: TelemetryEventNames.VA_NRT }, { splitTimeout: splitKey });
                                            clearTimeout(this.adPlayTimeoutRef[splitKey]);
                                            this.adPlayTimeoutRef[splitKey] = null;
                                            reject(new Error(AdErrorNames.AdPlaybackTimeout));
                                        },
                                        timeout * 1000,
                                    );
                                    this.adPlayTimeoutRef[splitKey] = myRef;
                                }
                            });
                        }
                        break;
                    }
                    case AdEventNames.adFirstQuartile:
                        split = 'firstQSplit';
                        break;
                    case AdEventNames.adSecondQuartile:
                        split = 'secondQSplit';
                        break;
                    case AdEventNames.adThirdQuartile:
                        split = 'thirdQSplit';
                        break;
                    case AdEventNames.adFourthQuartile:
                        split = 'fourthQSplit';
                        break;
                    case AdEventNames.adPaused:
                        Object.keys(this.adPlayTimeoutRef).forEach((key) => {
                            clearTimeout(this.adPlayTimeoutRef[key]);
                            this.adPlayTimeoutRef[key] = null;
                        });
                        break;
                    default:
                        break;
                }

                if (split && this.adPlayTimeoutRef[split]) {
                    clearTimeout(this.adPlayTimeoutRef[split]);
                    this.adPlayTimeoutRef[split] = null;
                }
            });
        });
        */
    }

    private cleanup(): void {
        // Clean up timeouts just in case
        clearTimeout(this.adStartTimeoutRef);
        this.adStartTimeoutRef = null;
        // Object.keys(this.adPlayTimeoutRef).forEach((ref) => {
        //     clearTimeout(this.adPlayTimeoutRef[ref]);
        //     this.adPlayTimeoutRef[ref] = null;
        // });
        // @xxx
        clearTimeout(this.adPlayTimeoutRef);
        this.adPlayTimeoutRef = null;

        // Clean up subscriptions
        this.adStartTimeoutSubscription.unsubscribe();
        this.adStartTimeoutSubscription = null;
        this.adPlayTimeoutSubscription.unsubscribe();
        this.adPlayTimeoutSubscription = null;

        this.isPlaying = false;
    }
}

/**
 * Wrapper class for transforming/formatting ad events
 * @param event MessageEvent
 * @returns IMessageEvent
 */
export const transformEvent = (event: MessageEvent): IMessageEvent => transformXandrEvent(event); // @todo: make this generic
